import React from 'react'

import {
  TextBlockStyled,
  TextBlockMediaStyled,
  TextBlockHeadStyled,
  TextBlockBodyStyled,
} from './styles'

interface IProps {
  src?: string
  alt?: string
  head?: React.ReactNode
  body?: React.ReactNode
}

const TextBlock: React.FC<IProps> = ({ src, alt, head, body }) =>
  src || head || body ? (
    <TextBlockStyled>
      {!!src && (
        <TextBlockMediaStyled>
          <img src={src} alt={alt} />
        </TextBlockMediaStyled>
      )}
      {!!head && <TextBlockHeadStyled>{head}</TextBlockHeadStyled>}
      {!!body && <TextBlockBodyStyled>{body}</TextBlockBodyStyled>}
    </TextBlockStyled>
  ) : null

export default TextBlock
