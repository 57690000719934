import React from 'react'

import {
  MainNavStyled,
  MainNavListStyled,
  MainNavItemStyled,
  MainNavLinkStyled,
  MainNavCtaStyled,
} from './styles'

import { NAVIGATION } from '../../constants/navigation'
import { IUrlItem } from '../../interfaces/IUrlItem'

export interface IUrlItemList {
  list: IUrlItem[]
}

interface IProps {
  action?: React.ReactNode
  list?: React.ReactNode
  headerType?: string
}

const MainNav: React.FC<IProps> = ({ list, action, headerType }) => (
  <MainNavStyled {...{ headerType }}>
    {list && (
      <MainNavListStyled>
        {NAVIGATION.map(({ title, url }) => (
          <MainNavItemStyled key={`${url}${title}`}>
            <MainNavLinkStyled to={url} activeClassName="active">
              {title}
            </MainNavLinkStyled>
          </MainNavItemStyled>
        ))}
      </MainNavListStyled>
    )}
    {action && <MainNavCtaStyled>{action}</MainNavCtaStyled>}
  </MainNavStyled>
)

export default MainNav
