import React from 'react'

import {
  ProfileBoxWrapperStyled,
  ProfileBoxStyled,
  ProfileBoxMediaStyled,
  ProfileBoxImgStyled,
  ProfileBoxTitleStyled,
  ProfileBoxDescStyled,
  ProfileBoxListStyled,
  ProfileBoxListItemStyled,
} from './styles'
import { Typo3, Typo6 } from '../Typography'

interface IProps {
  src: string
  alt: string
  name: string
  description: string
  bg?: string
  list: React.ReactNode[]
}

const ProfileBox: React.FC<IProps> = ({
  name,
  description,
  list,
  bg,
  ...otherProps
}) => (
  <ProfileBoxWrapperStyled {...{ bg }}>
    <ProfileBoxStyled>
      <ProfileBoxMediaStyled>
        <ProfileBoxImgStyled {...otherProps} />
      </ProfileBoxMediaStyled>
      <ProfileBoxTitleStyled>
        <Typo3 color="darkGrey" fontWeight="bold">
          {name}
        </Typo3>
      </ProfileBoxTitleStyled>
      <ProfileBoxDescStyled>
        <Typo6 fontWeight="500" color="mediumGrey">
          {description}
        </Typo6>
      </ProfileBoxDescStyled>
      <ProfileBoxListStyled>
        {list.map((el, index) => (
          <ProfileBoxListItemStyled key={index}>{el}</ProfileBoxListItemStyled>
        ))}
      </ProfileBoxListStyled>
    </ProfileBoxStyled>
  </ProfileBoxWrapperStyled>
)

export default ProfileBox
