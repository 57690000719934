import styled from 'styled-components'
import Link from 'gatsby-link'
import { fontSize } from 'styled-system'

const LogoLinkStyled = styled(Link)`
  display: flex;
`

const LogoIconStyled = styled.span`
  display: inline-flex;
  flex-shrink: 0;

  svg {
    ${fontSize}
    width: 1em;
    height: ${({ height }) => `${height} || 1em`};
    fill: currentColor;
  }
`

export { LogoLinkStyled, LogoIconStyled }
