import styled from 'styled-components'
import { color } from 'styled-system'

const ServiceIconsListWrapperStyled = styled.div`
  display: inline-block;
`

const ServiceIconsListStyled = styled.ul`
  display: flex;

  align-items: center;
  justify-items: center;
`

const ServiceIconsListItemStyled = styled.li`
  &:not(:last-child) {
    margin-right: 2.625rem;
  }
`

const ServiceIconsListItemMedia = styled.div`
  ${color}
  display: flex;
  opacity: 1;

  img {
    width: 100%;
  }
`

export {
  ServiceIconsListWrapperStyled,
  ServiceIconsListStyled,
  ServiceIconsListItemStyled,
  ServiceIconsListItemMedia,
}
