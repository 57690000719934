import React from 'react'

import {
  InfoBoxStyled,
  InfoBoxIconWrapperStyled,
  InfoBoxIconStyled,
  InfoBoxHeadingStyled,
  InfoBoxIconHeadingStyled,
  InfoBoxIconHeadingMediaStyled,
  InfoBoxIconHeadingTypoStyled,
  InfoBoxTitleStyled,
  InfoBoxDescStyled,
  InfoBoxActionStyled,
} from './styles'
import theme from '../../themes/theme'
import Link from '../Link'
import { Typo1, Typo6 } from '../Typography'

interface IProps {
  heading: string
  src?: string
  alt?: string
  title?: string
  centered?: boolean
  url?: string
  headIconSrc?: string
  light?: boolean
}

const InfoBox: React.FC<IProps> = ({
  heading,
  title,
  url,
  children,
  src,
  headIconSrc,
  ...otherProps
}) => (
  <InfoBoxStyled {...otherProps}>
    {src && (
      <InfoBoxIconWrapperStyled>
        <InfoBoxIconStyled src={src} {...otherProps} />
      </InfoBoxIconWrapperStyled>
    )}
    {headIconSrc ? (
      <InfoBoxIconHeadingStyled>
        <InfoBoxIconHeadingMediaStyled>
          <InfoBoxIconStyled src={headIconSrc} />
        </InfoBoxIconHeadingMediaStyled>

        <InfoBoxIconHeadingTypoStyled>
          <Typo1 as="h3" fontWeight="bold">
            {heading}
          </Typo1>
        </InfoBoxIconHeadingTypoStyled>
      </InfoBoxIconHeadingStyled>
    ) : (
      <InfoBoxHeadingStyled>
        <Typo1 as="h3" fontWeight="bold">
          {heading}
        </Typo1>
      </InfoBoxHeadingStyled>
    )}
    {(children || title) && (
      <InfoBoxDescStyled>
        {title && (
          <InfoBoxTitleStyled>
            <Typo6 fontWeight="bold" color={theme.colors.mediumGrey}>
              {title}
            </Typo6>
          </InfoBoxTitleStyled>
        )}

        {children}
      </InfoBoxDescStyled>
    )}
    {url && (
      <InfoBoxActionStyled>
        <Link withArrow iconColor={theme.colors.red} href={url}>
          More
        </Link>
      </InfoBoxActionStyled>
    )}
  </InfoBoxStyled>
)

export default InfoBox
