import React from 'react'

import ButtonLink from '../components/ButtonLink/index'
import { ButtonLinkType, ButtonLinkSize } from '../components/ButtonLink/styles'

export const HEADER_BUTTON_TYPES = {
  developers: (
    <ButtonLink
      className="button"
      type={ButtonLinkType.tertiary}
      size={ButtonLinkSize.small}
      href="https://jsminds.recruitee.com/"
      target="_blank"
    >
      Join Us
    </ButtonLink>
  ),
  companies: (
    <ButtonLink
      className="button"
      type={ButtonLinkType.tertiary}
      size={ButtonLinkSize.small}
      href="https://pipedrivewebforms.com/form/45d388f2f4deb5ef7d30266c2cd46b164551864"
      rel="noopener noreferrer"
      target="_blank"
    >
      Hire Now
    </ButtonLink>
  ),
}
