import { createGlobalStyle } from 'styled-components'
// @ts-ignore
import reset from 'styled-reset-advanced'

const GlobalStyles = createGlobalStyle`
  ${reset}

  * {
    user-select: auto;
  }

  html {
    font-size: 16px;
  }

  body {
    font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Droid Sans", 'Inter', "Helvetica Neue", sans-serif;
    line-height: 1.42;
    color: #061f2d;
  }

  iframe,
  img {
    display: block;
    max-width: 100%;
  }
`

export default GlobalStyles
