import React from 'react'

import {
  StyledFooter,
  StyledFooterWrapper,
  StyledFooterContainer,
  StyledFooterTop,
  StyledFooterBottom,
  StyledFooterTopContainer,
  StyledFooterTopContainerCol,
  StyledFooterBottomContainer,
  StyledFooterBottomContainerCol,
} from './styles'
import FooterItem from '../FooterItem/index'
import Copyrights from '../Copyrights/index'
import Inner from '../Inner'
import { IFooter } from '../../interfaces/IFooter'
import { FooterColumnType } from './styles'

const waterMark = require('../../assets/images/svg/logo-watermark-big.svg') as string

export interface IFooterItem {
  items: IFooter[]
}

interface IProps {
  developersSpecialties: IFooter
}

const Footer: React.FC<IProps> = ({ developersSpecialties }) => (
  <StyledFooter>
    <StyledFooterWrapper bg="tiber">
      <StyledFooterContainer
        backgroundImage={`url(${waterMark})`}
        pt={['2.875rem', '4.5rem']}
        pb={['2.875rem', '4.5rem']}
      >
        <Inner>
          <StyledFooterTop pb={['2rem', '2.1rem']}>
            <StyledFooterTopContainer>
              <StyledFooterTopContainerCol
                type={FooterColumnType.developersSpecialties}
              >
                <FooterItem
                  headingColor="white"
                  linkColor="mediumGrey2"
                  footerTitle={developersSpecialties.title}
                  footerList={developersSpecialties.list}
                />
              </StyledFooterTopContainerCol>
            </StyledFooterTopContainer>
          </StyledFooterTop>
          <StyledFooterBottom>
            <StyledFooterBottomContainer>
              <StyledFooterBottomContainerCol>
                <Copyrights />
              </StyledFooterBottomContainerCol>
            </StyledFooterBottomContainer>
          </StyledFooterBottom>
        </Inner>
      </StyledFooterContainer>
    </StyledFooterWrapper>
  </StyledFooter>
)

export default Footer
