import React from 'react'
import { Swiper, Slide } from 'react-dynamic-swiper'
import 'react-dynamic-swiper/lib/styles.css'

import {
  SliderWrapperStyled,
  SliderWrapperBodyStyled,
  SliderMobileContainerStyled,
} from './styles'
import Inner from '../Inner'
import ProfileBox from '../ProfileBox'
import { IDeveloper } from '../../interfaces/IDeveloper'
import { useWindowWidth } from '../../hooks/windowWidth'

interface IProps {
  slideBackground?: string
  items: IDeveloper[]
}

const Slider: React.FC<IProps> = ({ slideBackground, items }) => {
  const windowWidth = useWindowWidth()
  const MOBILE_BREAKPOINT = 768

  return (
    <SliderWrapperStyled>
      <SliderWrapperBodyStyled>
        <Inner>
          {windowWidth >= MOBILE_BREAKPOINT ? (
            <Swiper
              swiperOptions={{
                slidesPerView: 'auto',
                spaceBetween: 18,
                speed: 1000,
              }}
              navigation={false}
              pagination={false}
            >
              {items.map((el) => (
                <Slide key={el.name}>
                  <ProfileBox bg={slideBackground} {...el} />
                </Slide>
              ))}
            </Swiper>
          ) : (
            <SliderMobileContainerStyled>
              {items.map((el) => (
                <Slide key={el.name}>
                  <ProfileBox bg={slideBackground} {...el} />
                </Slide>
              ))}
            </SliderMobileContainerStyled>
          )}
        </Inner>
      </SliderWrapperBodyStyled>
    </SliderWrapperStyled>
  )
}

export default Slider
