import styled, { css } from 'styled-components'

const variables = {
  iconFontSize: '58px',
}

interface IInfoBoxStyledProps {
  centered?: boolean
  light?: boolean
}

const InfoBoxStyled = styled.div<IInfoBoxStyledProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ centered }: IInfoBoxStyledProps) =>
    centered &&
    css`
      align-items: center;
      text-align: center;
    `}

  ${({ light, theme }) =>
    light &&
    css`
      ${InfoBoxHeadingStyled} {
        color: ${theme.colors.white};
      }
    `}
`

const InfoBoxIconWrapperStyled = styled.figure`
  display: inline-block;
  line-height: 1em;
  font-size: ${variables.iconFontSize};
  margin-bottom: calc(${({ theme }) => theme.gutter.gutter24});
`

const InfoBoxIconStyled = styled.img`
  height: 1em;
`

const InfoBoxHeadingStyled = styled.div`
  margin-bottom: calc(${({ theme }) => theme.gutter.gutter24});
  color: ${({ theme }) => theme.colors.darkGrey};
`

const InfoBoxIconHeadingStyled = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: calc(${({ theme }) => theme.gutter.gutter24});
  color: ${({ theme }) => theme.colors.darkGrey};
`

const InfoBoxIconHeadingMediaStyled = styled.div`
  padding-right: 0.6rem;

  img {
    min-width: 1.6875rem;
    max-width: 1.6875rem;
    height: auto;
  }
`

const InfoBoxIconHeadingTypoStyled = styled.div``

const InfoBoxTitleStyled = styled.div`
  margin-bottom: calc(${({ theme }) => theme.gutter.gutter16} * 0.75);
`

const InfoBoxDescStyled = styled.div`
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.mediumGrey};
  margin-bottom: calc(${({ theme }) => theme.gutter.gutter24});

  p:not(:last-child) {
    margin-bottom: 10px;
  }
`

const InfoBoxActionStyled = styled.div``

export {
  InfoBoxStyled,
  InfoBoxIconWrapperStyled,
  InfoBoxIconStyled,
  InfoBoxHeadingStyled,
  InfoBoxIconHeadingStyled,
  InfoBoxIconHeadingMediaStyled,
  InfoBoxIconHeadingTypoStyled,
  InfoBoxTitleStyled,
  InfoBoxDescStyled,
  InfoBoxActionStyled,
}
