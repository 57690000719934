import styled from 'styled-components'
import {
  space,
  textAlign,
  color,
  position,
  overflow,
  width,
  backgroundImage,
} from 'styled-system'

const Container = styled.div`
  ${space}
  ${color}
  ${textAlign}
  ${position}
  ${overflow}
  ${width}
  ${backgroundImage}
`

export default Container
