import { IClientItem } from '../components/Clients'

const FinancialTimes = require('../assets/images/financial-times-logo.png') as string
const Forbes = require('../assets/images/forbes-logo.png') as string
const DailyMail = require('../assets/images/daily-mail-logo.png') as string
const HuntScanion = require('../assets/images/hunt-logo.png') as string
const Wsc = require('../assets/images/wsc-logo.png') as string
const Sia = require('../assets/images/sia-logo.png') as string

export const clients: IClientItem['items'] = [
  {
    src: FinancialTimes,
    alt: 'Financial Times',
  },
  {
    src: Forbes,
    alt: 'Forbes',
  },
  {
    src: DailyMail,
    alt: 'Daily Mail',
  },
  {
    src: HuntScanion,
    alt: 'Hunt Scanion',
  },
  {
    src: Wsc,
    alt: 'World Executive Search Congress',
  },
  {
    src: Sia,
    alt: 'Staffing Industry Analysts',
  },
]
