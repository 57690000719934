import styled from 'styled-components'
import { space, color } from 'styled-system'

const SectionHeadStyled = styled.div`
  ${space}
  ${color}
  width: 100%;

  span {
    color: ${({ theme }) => theme.colors.red};
  }
`

const SectionBlockStyled = styled.div`
  ${space}
  width: 100%;
`

export { SectionHeadStyled, SectionBlockStyled }
