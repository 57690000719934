import styled from 'styled-components'
import { flexDirection, space } from 'styled-system'

import { media } from '../../common/MediaQueries'

const CtaBoxStyled = styled.div`
  ${flexDirection}
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 3.875rem;
`

const CtaBoxContentStyled = styled.div`
  ${space}
  width: 100%;
  max-width: 35rem;

  @media ${media.phone} {
    padding-right: 2.5rem;
  }
`

const CtaBoxMediaStyled = styled.div`
  @media ${media.phone} {
    padding-top: 0.75rem;
  }
`

const CtaBoxHeaderStyled = styled.div`
  color: ${({ theme }) => theme.colors.lightGrey};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: ${({ theme }) => theme.lineHeight.small};
  margin-bottom: 1.375rem;

  span {
    color: ${({ theme }) => theme.colors.red};
  }
`

const CtaBoxMarkStyled = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.red};
`

const CtaBoxTextStyled = styled.div`
  color: ${({ theme }) => theme.colors.lightGrey};
  line-height: ${({ theme }) => theme.lineHeight.big};
  margin-bottom: 1.25rem;

  @media ${media.phone} {
    margin-bottom: 1.75rem;
  }
`

const CtaBoxActionsStyled = styled.div`
  display: flex;
  .button:not(:last-child) {
    margin-right: calc(${({ theme }) => theme.gutter.gutter32});
  }
`

export {
  CtaBoxStyled,
  CtaBoxContentStyled,
  CtaBoxMediaStyled,
  CtaBoxHeaderStyled,
  CtaBoxMarkStyled,
  CtaBoxTextStyled,
  CtaBoxActionsStyled,
}
