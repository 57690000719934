import React from 'react'

import {
  ServiceIconsListWrapperStyled,
  ServiceIconsListStyled,
  ServiceIconsListItemStyled,
  ServiceIconsListItemMedia,
} from './styles'

interface ISocialMediaItem {
  id: number
  icon: React.ReactNode
}

interface IProps {
  items: ISocialMediaItem[]
  color?: string
}

const ServiceIcons: React.FC<IProps> = ({ items, color }) => (
  <ServiceIconsListWrapperStyled>
    <ServiceIconsListStyled>
      {items.map(({ icon, id }) => (
        <ServiceIconsListItemStyled key={id}>
          <ServiceIconsListItemMedia {...{ color }}>
            {icon}
          </ServiceIconsListItemMedia>
        </ServiceIconsListItemStyled>
      ))}
    </ServiceIconsListStyled>
  </ServiceIconsListWrapperStyled>
)

export default ServiceIcons
