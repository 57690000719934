import React from 'react'

import {
  ClientsListWrapperStyled,
  ClientsListStyled,
  ClientsListItemStyled,
  ClientsListItemMedia,
} from './styles'
import { IImage } from '../../interfaces/IImage'

export interface IClientItem {
  items: IImage[]
}

const Clients: React.FC<IClientItem> = ({ items }) => (
  <ClientsListWrapperStyled>
    <ClientsListStyled>
      {items.map(({ src, alt }) => (
        <ClientsListItemStyled key={src}>
          <ClientsListItemMedia>
            <img src={src} alt={alt} />
          </ClientsListItemMedia>
        </ClientsListItemStyled>
      ))}
    </ClientsListStyled>
  </ClientsListWrapperStyled>
)

export default Clients
