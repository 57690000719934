import React from 'react'

import { HamburgerStyled, HamburgerLineStyled } from './styles'

interface IProps {
  clickHandler: () => void
  isOpened?: boolean
  headerType?: string
}

const Hamburger: React.FC<IProps> = ({
  clickHandler,
  isOpened,
  headerType,
}) => {
  return (
    <HamburgerStyled
      aria-label="Open menu"
      type="button"
      onClick={clickHandler}
      {...{ isOpened, headerType }}
    >
      <HamburgerLineStyled />
    </HamburgerStyled>
  )
}

export default Hamburger
