import styled from 'styled-components'

const TextBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
`

const TextBlockMediaStyled = styled.figure``

const TextBlockHeadStyled = styled.div`
  margin-bottom: 1.25rem;
`

const TextBlockBodyStyled = styled.div`
  p:not(:last-child) {
    margin-bottom: 1rem;
  }
`

export {
  TextBlockStyled,
  TextBlockMediaStyled,
  TextBlockHeadStyled,
  TextBlockBodyStyled,
}
