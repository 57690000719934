import React, { useState } from 'react'

import {
  HeaderWrapperStyled,
  HeaderStyled,
  HeaderLogoStyled,
  HeaderNavigationWrapperStyled,
  HeaderNavigationStyled,
} from './styles'

import Logo from '../Logo/index'
import MainNav from '../MainNav/index'
import Inner from '../Inner'
import Hamburger from '../Hamburger'

interface IProps {
  withLogoLabel?: boolean
  actionButton?: React.ReactNode
  headerType?: string
}

const Header: React.FC<IProps> = ({
  withLogoLabel,
  actionButton,
  headerType,
}) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenuHandler = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <HeaderWrapperStyled {...{ headerType }}>
      <Inner>
        <HeaderStyled>
          <HeaderLogoStyled>
            <Logo
              withLabel={withLogoLabel}
              fontSize={['8rem', '11rem']}
              height="auto"
              href="/"
            />
          </HeaderLogoStyled>
          <HeaderNavigationWrapperStyled>
            <HeaderNavigationStyled isOpened={menuOpen}>
              <MainNav list action={actionButton} {...{ headerType }} />
            </HeaderNavigationStyled>
            <Hamburger
              isOpened={menuOpen}
              clickHandler={toggleMenuHandler}
              {...{ headerType }}
            />
          </HeaderNavigationWrapperStyled>
        </HeaderStyled>
      </Inner>
    </HeaderWrapperStyled>
  )
}

export default Header
