import React, { useMemo } from 'react'

import {
  StyledCopyrights,
  StyledCopyrightsHead,
  StyledCopyrightsBody,
} from './styles'
import Logo from '../Logo/index'
import { Typo6 } from '../Typography/index'

const Copyrights = () => {
  const year = useMemo(() => new Date().getFullYear(), [])
  return (
    <StyledCopyrights>
      <StyledCopyrightsHead>
        <Logo fontSize={['2rem', '']} href="./" />
      </StyledCopyrightsHead>
      <StyledCopyrightsBody>
        <Typo6 color="mediumGrey2">JS Minds {year}. All rights reserved.</Typo6>
      </StyledCopyrightsBody>
    </StyledCopyrights>
  )
}

export default Copyrights
