import styled from 'styled-components'
import Container from '../Container'

const ProfileBoxWrapperStyled = styled(Container)`
  display: flex;
`

const ProfileBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.3rem 2rem;
`

const ProfileBoxMediaStyled = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 100%;
  flex-shrink: 0;
  align-items: center;
  width: 5.75rem;
  height: 5.75rem;
  margin-bottom: calc(${({ theme }) => theme.gutter.gutter16} * 1.25);
`

const ProfileBoxImgStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  margin: 0 auto;
`

const ProfileBoxTitleStyled = styled.div`
  margin-bottom: 1rem;
`

const ProfileBoxDescStyled = styled.div`
  margin-bottom: 1rem;
`

const ProfileBoxListStyled = styled.ul``

const ProfileBoxListItemStyled = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.lightGrey2};
  padding-left: 2rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 1.125rem;
    height: 0.1875rem;
    background-color: ${({ theme }) => theme.colors.black};
  }

  span {
    color: ${({ theme }) => theme.colors.red};
  }
`

export {
  ProfileBoxWrapperStyled,
  ProfileBoxStyled,
  ProfileBoxMediaStyled,
  ProfileBoxImgStyled,
  ProfileBoxTitleStyled,
  ProfileBoxDescStyled,
  ProfileBoxListStyled,
  ProfileBoxListItemStyled,
}
