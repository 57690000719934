import { URL_PATHS } from './urlPaths'
import { IUrlItemList } from '../components/MainNav'

export const NAVIGATION: IUrlItemList['list'] = [
  {
    title: 'Companies',
    url: URL_PATHS.COMPANIES,
  },
  {
    title: 'Developers',
    url: URL_PATHS.DEVELOPERS,
  },
]
