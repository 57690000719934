import { IFooter } from '../interfaces/IFooter'

export const companies: IFooter = {
  title: 'Companies',
  list: [
    {
      title: 'How it works?',
      url: '/',
    },
    {
      title: 'Resources',
      url: '/',
    },
  ],
}

export const developers: IFooter = {
  title: 'Developers',
  list: [
    {
      title: 'Why join JS Minds?',
      url: '/',
    },
    {
      title: 'Dev Houses Partnership',
      url: '/',
    },
  ],
}

export const developersSpecialties: IFooter = {
  title: 'Core Specialties',
  list: [
    {
      title: 'React Developers',
      url: '/',
    },
    {
      title: 'React Native Developers',
      url: '/',
    },
  ],
}

export const others: IFooter = {
  title: 'Others',
  list: [
    {
      title: 'Terms of use',
      url: '/',
    },
    {
      title: 'FAQ',
      url: '/',
    },
    {
      title: 'Privacy Policy',
      url: '/',
    },
  ],
}
