import styled, { css } from 'styled-components'
import Link from 'gatsby-link'
import { color } from 'styled-system'

import { media } from '../../common/MediaQueries'
import { transition } from '../../common/mixins'

// link styles mixin
const footerLinksStyles = css`
  ${color}
  font-size: 0.875rem;
  line-height: 1.8;
`

const StyledFooterItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledFooterItemHead = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 0.8rem;
`

const StyledFooterItemBody = styled.div`
  width: 100%;
`

const StyledFooterItemList = styled.ul``

const StyledFooterItemListItem = styled.li`
  margin-bottom: 0.25rem;

  @media ${media.phone} {
    margin-bottom: 0.0625rem;
  }
`

const StyledFooterItemLink = styled.p`
  display: flex;
  ${footerLinksStyles}
`

export {
  StyledFooterItem,
  StyledFooterItemHead,
  StyledFooterItemBody,
  StyledFooterItemList,
  StyledFooterItemListItem,
  StyledFooterItemLink,
}
