import React from 'react'
import { SvgComponent } from '../../definitions/Svg'

const GatsbyIcon: SvgComponent = (props) => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M18.5 0C8.28272 0 0 8.28271 0 18.5C0 28.7173 8.28276 37 18.5 37C28.7173 37 36.9999 28.7173 36.9999 18.5C36.9999 8.28271 28.7173 0 18.5 0ZM3.97518 18.6929L18.3071 33.0248C10.4372 32.9222 4.07772 26.5627 3.97518 18.6929ZM21.7514 32.6617L4.33834 15.2486C5.81489 8.79086 11.5946 3.97276 18.5 3.97276C23.3269 3.97276 27.6032 6.32759 30.245 9.95046L28.2336 11.7252C26.0907 8.65218 22.5305 6.64102 18.5 6.64102C13.3684 6.64102 8.99843 9.90056 7.34668 14.462L22.538 29.6533C26.2272 28.3174 29.0645 25.2032 30.0147 21.3462H23.7179V18.5H30.3589V18.5H33.0272V18.5C33.0272 25.4054 28.2091 31.1851 21.7514 32.6617Z" />
  </svg>
)

export default GatsbyIcon
