import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { HEADER_TYPES } from '../../constants/headerTypes'
import { media, customMedia } from '../../common/MediaQueries'
import { decorLink } from '../../common/mixins'

const MainNavListStyled = styled.ul`
  display: flex;

  @media ${customMedia.maxPhone} {
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.gutter.gutter24};
  }

  @media ${media.phone} {
    margin-right: ${(props) => props.theme.gutter.gutter32};
  }
`

const MainNavItemStyled = styled.li`
  @media ${customMedia.maxPhone} {
    &:not(:last-child) {
      margin-bottom: ${(props) => props.theme.gutter.gutter16};
    }
  }
  @media ${media.phone} {
    &:not(:last-child) {
      margin-right: ${(props) => props.theme.gutter.gutter32};
    }
  }
`

const MainNavLinkStyled = styled(Link)`
  ${({ theme }) =>
    decorLink({
      height: '2px',
      hoverUnderlineBg: theme.colors.red,
      theme,
    })}
  display: block;
  color: ${({ theme }) => theme.colors.lightBlue};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &:hover {
    color: white;
  }

  @media ${customMedia.maxPhone} {
    color: ${({ theme }) => theme.colors.white};
  }

  @media ${media.phone} {
    font-size: 0.875rem;
  }
`

const MainNavCtaStyled = styled.div``

const MainNavStyled = styled.div`
  display: flex;
  align-items: center;


  ${({ headerType, theme }) =>
    headerType === HEADER_TYPES.light &&
    css`
      ${MainNavLinkStyled} {
        color: ${theme.colors.lightGrey2};

        &:hover,
        &.active {
          color: ${theme.colors.tiber};
        }
      }
    `}

  ${({ headerType, theme }) =>
    headerType === HEADER_TYPES.dark &&
    css`
      ${MainNavLinkStyled} {
        color: ${theme.colors.lightBlue};

        &:hover,
        &.active {
          color: ${theme.colors.white};
        }
      }
    `}


  @media ${customMedia.maxPhone} {
    width: 100%;
    flex-direction: column;

    margin: 6rem 0;
    max-height: calc(100vh - 12rem);
  }
`

export {
  MainNavStyled,
  MainNavListStyled,
  MainNavItemStyled,
  MainNavLinkStyled,
  MainNavCtaStyled,
}
