import React from 'react'

import {
  CtaBoxStyled,
  CtaBoxContentStyled,
  CtaBoxMediaStyled,
  CtaBoxHeaderStyled,
  CtaBoxTextStyled,
  CtaBoxActionsStyled,
} from './styles'

interface IProps {
  title: React.ReactNode
  description?: React.ReactNode
  action?: React.ReactNode
  media?: React.ReactNode
  direction?: string[]
  pbContent?: string[]
}

const CtaBox: React.FC<IProps> = ({
  title,
  description,
  action,
  media,
  direction,
  pbContent,
}) => (
  <CtaBoxStyled flexDirection={direction}>
    <CtaBoxContentStyled pb={pbContent}>
      <CtaBoxHeaderStyled>{title}</CtaBoxHeaderStyled>
      {description && <CtaBoxTextStyled>{description}</CtaBoxTextStyled>}
      {action && <CtaBoxActionsStyled>{action}</CtaBoxActionsStyled>}
    </CtaBoxContentStyled>
    {media && <CtaBoxMediaStyled>{media}</CtaBoxMediaStyled>}
  </CtaBoxStyled>
)

export default CtaBox
