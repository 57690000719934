import React from 'react'
import { SvgComponent } from 'definitions/Svg'

const GitHub: SvgComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 17 16" {...props}>
    <path
      d="M15.524 4.084A8.225 8.225 0 0012.496 1.1 8.229 8.229 0 008.32 0a8.23 8.23 0 00-4.176 1.1 8.224 8.224 0 00-3.028 2.984A7.933 7.933 0 000 8.2c0 1.787.529 3.394 1.587 4.821a8.142 8.142 0 004.1 2.963c.195.036.34.01.434-.074a.415.415 0 00.14-.32l-.005-.577a93.95 93.95 0 01-.005-.95l-.25.042c-.159.029-.359.041-.6.038a4.66 4.66 0 01-.754-.075 1.694 1.694 0 01-.726-.32 1.356 1.356 0 01-.476-.657l-.109-.246a2.662 2.662 0 00-.34-.544c-.156-.2-.313-.335-.472-.406l-.076-.053a.795.795 0 01-.14-.129.585.585 0 01-.098-.149c-.022-.05-.004-.09.054-.123.058-.032.163-.048.314-.048l.217.032c.144.029.323.114.536.256.213.143.388.328.525.555.167.292.367.515.602.668.234.153.471.23.71.23.238 0 .443-.018.617-.054.173-.035.335-.089.487-.16.065-.477.242-.844.53-1.1a7.509 7.509 0 01-1.11-.192 4.457 4.457 0 01-1.018-.417 2.907 2.907 0 01-.872-.715c-.23-.285-.42-.659-.568-1.121-.148-.463-.222-.997-.222-1.602 0-.861.285-1.595.855-2.2-.267-.647-.242-1.373.076-2.178.21-.064.52-.016.932.144.412.16.713.298.904.411.192.114.345.21.46.289a7.798 7.798 0 012.08-.278c.716 0 1.41.093 2.081.278l.412-.256c.281-.171.614-.328.996-.47.383-.143.676-.182.878-.118.325.805.354 1.53.086 2.178.57.606.856 1.339.856 2.2 0 .605-.074 1.14-.222 1.607-.148.466-.339.84-.574 1.121a3.019 3.019 0 01-.877.71c-.35.192-.69.331-1.019.417-.328.085-.698.15-1.11.192.375.32.563.826.563 1.516v2.253c0 .128.045.235.136.32.09.086.232.11.428.075a8.141 8.141 0 004.1-2.963c1.058-1.427 1.587-3.034 1.587-4.821a7.94 7.94 0 00-1.116-4.116z"
    />
  </svg>
)

export default GitHub
