import styled, { css } from 'styled-components'

import Container from '../Container'
import { media } from '../../common/MediaQueries'
import { WithTheme } from '../../themes/theme'

export enum FooterColumnType {
  companies,
  developers,
  developersSpecialties,
  others,
}

interface IProps {
  type: FooterColumnType
}

type StyledFooterTopContainerColProps = WithTheme<IProps>

const StyledFooter = styled.footer`
  width: 100%;
`

const StyledFooterWrapper = styled(Container)`
  width: 100%;
`

const StyledFooterContainer = styled(Container)`
  max-width: 87.625rem;
  margin: 0 auto;
  background-position: 110% 110%;
  background-size: 21.5625rem;
  background-repeat: no-repeat;

  @media ${media.phone} {
    background-position: 0 4.5rem;
    background-size: 27.8125rem;
  }
`

const StyledFooterTop = styled(Container)`
  width: 100%;
`

const StyledFooterBottom = styled(Container)`
  width: 100%;
`

const StyledFooterTopContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.625rem;
`

const StyledFooterTopContainerCol = styled.div`
  display: flex;
  padding: 0.625rem;


  ${({ type }: StyledFooterTopContainerColProps) =>
    type === FooterColumnType.companies &&
    css`
      flex-basis: 100%;
      max-width: 100%;

      @media ${media.phone} {
        flex-basis: 50%;
        max-width: 50%;
      }

      @media ${media.tablet} {
        flex-basis: 20%;
        max-width: 20%;
      }
    `}


    ${({ type }: StyledFooterTopContainerColProps) =>
      type === FooterColumnType.developers &&
      css`
        flex-basis: 100%;
        max-width: 100%;

        @media ${media.phone} {
          flex-basis: 50%;
          max-width: 50%;
        }

        @media ${media.tablet} {
          flex-basis: 23%;
          max-width: 23%;
        }
      `}

      ${({ type }: StyledFooterTopContainerColProps) =>
        type === FooterColumnType.developersSpecialties &&
        css`
          flex-basis: 100%;
          max-width: 100%;

          @media ${media.phone} {
            flex-basis: 50%;
            max-width: 50%;
          }

          @media ${media.tablet} {
            flex-basis: 26%;
            max-width: 26%;
          }
        `}

        ${({ type }: StyledFooterTopContainerColProps) =>
          type === FooterColumnType.others &&
          css`
            flex-basis: 100%;
            max-width: 100%;

            @media ${media.phone} {
              flex-basis: 50%;
              max-width: 50%;
            }

            @media ${media.tablet} {
              flex-basis: 31%;
              max-width: 31%;
            }
          `}
`

const StyledFooterBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${media.phone} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const StyledFooterBottomContainerCol = styled.div`
  display: flex;
  flex-grow: 1;
`

export {
  StyledFooter,
  StyledFooterWrapper,
  StyledFooterContainer,
  StyledFooterTop,
  StyledFooterTopContainer,
  StyledFooterTopContainerCol,
  StyledFooterBottom,
  StyledFooterBottomContainer,
  StyledFooterBottomContainerCol,
}
