import '../fonts/fonts.css'

const theme = {
  colors: {
    darkNavy: '#061F2d',
    navy: '#19425a',
    black: '#000',
    white: '#fff',
    red: '#F33540',
    red2: '#E04747',
    red3: '#F5333F',
    grey: '#E5E5E5',
    lightGrey: '#F3F3F3',
    lightGrey2: '#9E9E9E',
    mediumGrey: '#585858',
    mediumGrey2: '#96ADBA',
    darkGrey: '#171717',
    darkGrey2: '#1E3F52',
    blue: '#1C4860',
    lightBlue: '#87A9BB',
    lightBlue2: '#52819A',
    tiber: '#0A2B3D',
    lightTiber: '#133F57',
  },

  breakpoints: ['768px', '1025px'],
  sizes: {
    inner: '1000px',
    offset: '1.875rem',
  },
  fontFamilies: {
    lato: `'Lato', sans-serif;`,
    galanoGrotesque: `'GalanoGrotesque', sans-serif;`,
  },
  // Base Size: 16px
  textStyles: {
    typo1: {
      fontSize: ['1rem'], // 16px
    },
    typo2: {
      fontSize: ['3rem'], // 48px
    },
    typo3: {
      fontSize: ['1.5rem'], // 24px
    },
    typo4: {
      fontSize: ['1.563rem'], // 23.44px
    },
    typo5: {
      fontSize: ['1.25rem'], // 18.75px
    },
    typo6: {
      fontSize: ['0.875rem'], // 14px
    },
    typo7: {
      fontSize: ['0.8rem'], // 12px
    },
    typo8: {
      fontSize: ['1.5rem'], // 24px
    },
  },
  fontSize: {
    fontSize14: '14px',
    fontSize15: '15px',
    fontSize18: '18px',
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
  },
  lineHeight: {
    big: 1.5,
    medium: 1.25,
    small: 1.15,
    normal: 1,
  },
  btn: {
    width: '168px',
    heightSmall: '38px',
    heightMedium: '49px',
    heightBig: '60px',
    primaryBgHover: '#e72934',
    secondaryBgHover: '#e1ecF2',
  },
  opacities: {
    opacity20: 0.2,
    opacity60: 0.6,
  },
  gutter: {
    gutter8: '8px',
    gutter16: '16px',
    gutter12: '12px',
    gutter24: '24px',
    gutter32: '32px',
    gutter36: '36px',
    gutter40: '40px',
    gutter48: '48px',
    gutter64: '64px',
  },
  form: {
    fieldHeight: '54px',
    checkboxSize: '20px',
    checkboxMarkWidth: '6px',
    checkboxMarkHeight: '11px',
    radioMarkSize: '8px',
  },
  headerHeight: {
    mobile: '64px',
    desktop: '108px',
  },
  zIndex: {
    index10: '10',
  },
}

export default theme

export type Theme = typeof theme

export type WithTheme<T = {}> = { theme: Theme } & T
