import styled, { css } from 'styled-components'

import { HEADER_TYPES } from '../../constants/headerTypes'
import { media } from '../../common/MediaQueries'
import { transition } from '../../common/mixins'

const HamburgerLineStyled = styled.span`
  position: relative;
  display: flex;
  flex-shrink: 0;
  height: 0.125rem;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: currentColor;

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    height: 0.125rem;
    background-color: currentColor;
    ${transition}
  }

  &::before {
    top: 0.5rem;
  }

  &::after {
    bottom: 0.5rem;
  }
`

const HamburgerStyled = styled.button`
  position: relative;
  display: flex;
  width: 1.5rem;
  height: 1.25rem;
  z-index: 100;
  transform: translateZ(0);
  cursor: pointer;

  ${({ headerType, theme }) =>
    headerType === HEADER_TYPES.dark &&
    css`
      ${HamburgerLineStyled} {
        background-color: ${theme.colors.white};
        color: ${theme.colors.white};
      }
    `}

  ${({ headerType, theme }) =>
    headerType === HEADER_TYPES.light &&
    css`
      ${HamburgerLineStyled} {
        background-color: ${theme.colors.darkNavy};
        color: ${theme.colors.darkNavy};
      }
    `}

  ${({ isOpened }) =>
    isOpened &&
    css`
      ${HamburgerLineStyled} {
        background-color: transparent;
        transform-origin: 50% 50%;
      }

      ${HamburgerLineStyled} {
        &::before {
          top: 0;
          transform: rotate3d(0, 0, 1, -45deg);
        }
      }

      ${HamburgerLineStyled} {
        &::after {
          bottom: 0;
          transform: rotate3d(0, 0, 1, 45deg);
        }
      }
    `}

    @media ${media.phone} {
      display: none;
    }
`

export { HamburgerStyled, HamburgerLineStyled }
