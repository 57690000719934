import styled from 'styled-components'

const StyledCopyrights = styled.div`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
`
const StyledCopyrightsHead = styled.div`
  margin-bottom: 0.6875rem;
`
const StyledCopyrightsBody = styled.div``

export { StyledCopyrights, StyledCopyrightsHead, StyledCopyrightsBody }
