import styled, { css } from 'styled-components'

import { transition } from '../../common/mixins'
import { WithTheme } from '../../themes/theme'

export enum ButtonLinkSize {
  small,
  medium,
  big,
}

export enum ButtonLinkType {
  primary,
  secondary,
  tertiary,
  outline,
}

export enum ButtonLinkWidth {
  full,
}

interface IProps {
  size?: ButtonLinkSize
  buttonType?: ButtonLinkType
  left?: boolean
  fluid?: ButtonLinkWidth
  dark?: boolean
}

type ButtonLinkStyledProps = WithTheme<IProps>

const ButtonLinkStyled = styled.a`
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  width: auto;
  min-width: ${({ theme }: ButtonLinkStyledProps) => theme.btn.width};
  font-size: ${({ theme }: ButtonLinkStyledProps) => theme.fontSize.fontSize18};
  font-weight: ${({ theme }: ButtonLinkStyledProps) => theme.fontWeight.black};
  justify-content: center;
  padding: 0 calc(${({ theme }: ButtonLinkStyledProps) =>
    theme.gutter.gutter24});
  cursor: pointer;
  ${transition}

  /* Primary */
  ${({ buttonType, theme }: ButtonLinkStyledProps) =>
    buttonType === ButtonLinkType.primary &&
    css`
      background-color: ${theme.colors.red};
      color: ${theme.colors.white};

      &:hover {
        background-color: ${theme.btn.primaryBgHover};
      }
    `}

  /* Secondary */
  ${({ buttonType, theme }) =>
    buttonType === ButtonLinkType.secondary &&
    css`
      background-color: ${theme.colors.white};
      color: ${theme.colors.red};

      &:hover {
        background-color: ${theme.btn.secondaryBgHover};
      }
    `}

  /* Outline */
  ${({ buttonType, theme }: ButtonLinkStyledProps) =>
    buttonType === ButtonLinkType.outline &&
    css`
      background-color: ${theme.colors.white};
      border: 2px solid ${theme.colors.lightGrey};
      min-width: 100%;

      &:hover {
        background-color: ${theme.colors.lightGrey};
      }
    `}

  /* Tertiary */
  ${({ buttonType, theme }) =>
    buttonType === ButtonLinkType.tertiary &&
    css`
      background-color: transparent;
      color: currentColor;
      border: 2px solid ${theme.colors.red2};
      font-size: ${({ theme }: ButtonLinkStyledProps) =>
        theme.fontSize.fontSize14};
      padding: 0
        calc(${({ theme }: ButtonLinkStyledProps) => theme.gutter.gutter12});
      min-width: 5.5rem;

      &:hover {
        background-color: ${theme.colors.red2};
        color: ${theme.colors.white};
      }
    `}
  
  /* Small */
  ${({ size, theme }: ButtonLinkStyledProps) =>
    size === ButtonLinkSize.small &&
    css`
      height: ${theme.btn.heightSmall};
    `}

  /* Medium */
  ${({ size, theme }: ButtonLinkStyledProps) =>
    size === ButtonLinkSize.medium &&
    css`
      height: ${theme.btn.heightMedium};
    `}

  /* Big */
  ${({ size, theme }: ButtonLinkStyledProps) =>
    size === ButtonLinkSize.big &&
    css`
      height: ${theme.btn.heightBig};
    `}

  /* Fullwidth */
  ${({ fluid, theme }: ButtonLinkStyledProps) =>
    fluid === ButtonLinkWidth.full &&
    css`
      min-width: 100%;
    `}

  /* Left */
  ${({ left }: ButtonLinkStyledProps) =>
    left &&
    css`
      justify-content: flex-start;
    `}
`

export default ButtonLinkStyled
