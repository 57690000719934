import styled from 'styled-components'
import { space } from 'styled-system'

import { media } from '../../common/MediaQueries'

const SliderWrapperStyled = styled.div`
  position: relative;
  width: 100%;

  .swiper-container {
    overflow: unset;
  }

  .swiper-slide {
    display: flex;
    min-height: 100%;
    height: auto;
    max-width: 15rem;
    cursor: grab;

    @media ${media.phone} {
      max-width: 17.625rem;
    }
  }
`

const SliderWrapperBodyStyled = styled.div`
  ${space}
`

const SliderMobileContainerStyled = styled.div`
  position: relative;
  display: flex;
  margin-right: -${({ theme }) => theme.sizes.offset};
  overflow: auto;

  .swiper-slide {
    margin-right: 1rem;
  }
`

export {
  SliderWrapperStyled,
  SliderWrapperBodyStyled,
  SliderMobileContainerStyled,
}
