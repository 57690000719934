import React from 'react'

import {
  StyledFooterItem,
  StyledFooterItemHead,
  StyledFooterItemBody,
  StyledFooterItemList,
  StyledFooterItemListItem,
  StyledFooterItemLink,
} from './styles'

import { Typo1 } from '../Typography/index'
import { generateKey } from '../../common/utils'
import { IFooter } from '../../interfaces/IFooter'

interface IProps {
  footerTitle: IFooter['title']
  footerList: IFooter['list']
  headingColor?: string
  linkColor?: string
}

const FooterItem: React.FC<IProps> = ({
  footerTitle,
  footerList,
  headingColor,
  linkColor,
}) => (
  <StyledFooterItem>
    <StyledFooterItemHead>
      <Typo1 color={headingColor} fontWeight="bold">
        {footerTitle}
      </Typo1>
    </StyledFooterItemHead>
    <StyledFooterItemBody>
      <StyledFooterItemList>
        {footerList.map(({ title, url, target }, index) => (
          <StyledFooterItemListItem key={generateKey(title, index)}>
            {target ? (
              <StyledFooterItemLink color={linkColor}>
                {title}
              </StyledFooterItemLink>
            ) : (
              <StyledFooterItemLink to={url} color={linkColor}>
                {title}
              </StyledFooterItemLink>
            )}
          </StyledFooterItemListItem>
        ))}
      </StyledFooterItemList>
    </StyledFooterItemBody>
  </StyledFooterItem>
)

export default FooterItem
