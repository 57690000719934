import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import GlobalStyles from '../common/GlobalStyles'
import theme from '../themes/theme'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Main from '../components/Main'

import { HEADER_TYPES } from '../constants/headerTypes'

import { developersSpecialties } from '../mocks/footerMock'

interface IProps {
  headerType: string
  headerButtonType?: React.ReactNode
  children: React.ReactNode
}

const Layout: React.FC<IProps> = ({
  headerType,

  headerButtonType,
  children,
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Header
          headerType={headerType}
          withLogoLabel
          actionButton={headerButtonType}
        />

        <Main>{children}</Main>
        <Footer developersSpecialties={developersSpecialties} />
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
