import React from 'react'
import { IDeveloper } from '../interfaces/IDeveloper'

import { Typo6 } from '../components/Typography'

const KateK = require('../assets/images/pic-avatar-kot.jpg') as string
const JakobD = require('../assets/images/pic-avatar-jackob.jpg') as string
const DavidP = require('../assets/images/pic-avatar-david.jpg') as string
const RafalG = require('../assets/images/pic-avatar-rafal.jpg') as string
const TomC = require('../assets/images/pic-avatar-tom.jpg') as string
const TomM = require('../assets/images/pic-avatar-tom-m.jpg') as string
const JoannaD = require('../assets/images/pic-avatar-joanna.jpg') as string

export const developers: IDeveloper[] = [
  {
    src: DavidP,
    alt: 'David P.',
    name: 'David P.',
    description:
      'Fullstack Techlead Developper: Node React Apollo',
    list: [
      <Typo6>
        React JS <span>10/10</span>
      </Typo6>,
      <Typo6>
        JavaScript <span>10/10</span>
      </Typo6>,
      <Typo6>
        TypeScript <span>9/10</span>
      </Typo6>,
      <Typo6>
        Node.js <span>10/10</span>
      </Typo6>,
    ],
  },
  {
    src: JakobD,
    alt: 'Jakub D.',
    name: 'Jakub D.',
    description:
      'Senior React Team Leader and Trainer',
    list: [
      <Typo6>
        React JS <span>10/10</span>
      </Typo6>,
      <Typo6>
        React Native <span>5/10</span>
      </Typo6>,
      <Typo6>
        JavaScript <span>10/10</span>
      </Typo6>,
      <Typo6>
        TypeScript <span>10/10</span>
      </Typo6>,
    ],
  },
  {
    src: KateK,
    alt: 'Kate K.',
    name: 'Kate K.',
    description:
      'Senior UI / Front-end Developer and Trainer',
    list: [
      <Typo6>
        Gatsby JS <span>9/10</span>
      </Typo6>,
      <Typo6>
        React JS <span>9/10</span>
      </Typo6>,
      <Typo6>
        JavaScript <span>8/10</span>
      </Typo6>,
      <Typo6>
        TypeScript <span>7/10</span>
      </Typo6>,
    ],
  },
  {
    src: RafalG,
    alt: 'Rafal G',
    name: 'Rafal G.',
    description:
      'React & React Native developer with training experience',
    list: [
      <Typo6>
        React JS <span>10/10</span>
      </Typo6>,
      <Typo6>
        React Native <span>6/10</span>
      </Typo6>,
      <Typo6>
        JavaScript <span>10/10</span>
      </Typo6>,
      <Typo6>
        TypeScript <span>9/10</span>
      </Typo6>,
    ],
  },
  {
    src: JoannaD,
    alt: 'Joanna D',
    name: 'Joanna D.',
    description:
      'Senior UI / Front-end Developer with training experience',
    list: [
      <Typo6>
        React JS <span>9/10</span>
      </Typo6>,
      <Typo6>
        Design Systems <span>10/10</span>
      </Typo6>,
      <Typo6>
        JavaScript <span>9/10</span>
      </Typo6>,
      <Typo6>
        TypeScript <span>8/10</span>
      </Typo6>,
    ],
  },
  {
    src: TomC,
    alt: 'Tom C',
    name: 'Tom C.',
    description:
      'Expert React & TypeScript Developer',
    list: [
      <Typo6>
        React JS <span>10/10</span>
      </Typo6>,
      <Typo6>
        Next JS <span>9/10</span>
      </Typo6>,
      <Typo6>
        JavaScript <span>10/10</span>
      </Typo6>,
      <Typo6>
        TypeScript <span>10/10</span>
      </Typo6>,
    ],
  },
  {
    src: TomM,
    alt: 'Tom M',
    name: 'Tom M.',
    description:
      'React & React Native Developer with team leading experience',
    list: [
      <Typo6>
        React JS <span>9/10</span>
      </Typo6>,
      <Typo6>
        React Native <span>7/10</span>
      </Typo6>,
      <Typo6>
        JavaScript <span>10/10</span>
      </Typo6>,
      <Typo6>
        TypeScript <span>10/10</span>
      </Typo6>,
    ],
  },
]
