import styled, { css } from 'styled-components'

import { media } from '../../common/MediaQueries'
import Container from '../Container'

const HeroContainerStyled = styled(Container)`
  @media ${media.phone} {
    width: 100%;
    background-size: 53rem;
    background-repeat: no-repeat;
    background-position: 100% 100%;
  }
`

const HeroBgStyled = styled(Container)`
  position: relative;
  overflow: hidden;

  ${({ withBgMask, theme }) =>
    withBgMask &&
    css`
      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 3.5rem;
        width: 31.25rem;
        height: 10.25rem;
        background-color: ${theme.colors.tiber};

        @media ${media.phone} {
          top: 8.75rem;
          right: 0;
          left: auto;
          width: 93%;
          height: 22.25rem;
        }
      }
    `}
`

const HeroStyled = styled(Container)`
  position: relative;
  width: 100%;
  z-index: 2;
`

export { HeroContainerStyled, HeroStyled, HeroBgStyled }
