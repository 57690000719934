import styled, { css } from 'styled-components'

import { media } from '../common/MediaQueries'

const Inner = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: ${({ theme }) => theme.sizes.offset};
  padding-right: ${({ theme }) => theme.sizes.offset};
  max-width: ${({ theme }) => theme.sizes.inner};

  ${({ alt }) =>
    alt &&
    css`
      padding-left: 0;
      padding-right: 0;

      @media ${media.phone} {
        padding-left: ${({ theme }) => theme.sizes.offset};
        padding-right: ${({ theme }) => theme.sizes.offset};
      }
    `}
`

export default Inner
