import styled from 'styled-components'

import { media } from '../../common/MediaQueries'

const ClientsListWrapperStyled = styled.div`
  width: 100%;
`

const ClientsListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0 -0.625rem;
`

const ClientsListItemStyled = styled.li`
  display: flex;
  justify-content: center;
  padding: 0.625rem;
  width: 50%;
  margin-bottom: 2rem;

  @media ${media.phone} {
    justify-content: flex-start;
    width: calc(100% / 6);
    margin-bottom: 0;
  }
`

const ClientsListItemMedia = styled.div`
  display: flex;
  opacity: 1;
  transition: 0.25s;

  &:hover {
    opacity: 0.7;
  }
`

export {
  ClientsListWrapperStyled,
  ClientsListStyled,
  ClientsListItemStyled,
  ClientsListItemMedia,
}
