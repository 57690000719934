import styled from 'styled-components'
import { media } from '../common/MediaQueries'

const Main = styled.main`
  margin-top: ${({ theme }) => theme.headerHeight.mobile};

  @media ${media.phone} {
    margin-top: ${({ theme }) => theme.headerHeight.desktop};
  }
`

export default Main
