import React from 'react'

import { HeroContainerStyled, HeroStyled, HeroBgStyled } from './styles'
import Inner from '../Inner'

interface IProps {
  containerBackgroundImage?: string
  pt?: string[]
  pb?: string[]
  withBgMask?: boolean
}

const Hero: React.FC<IProps> = ({
  children,
  containerBackgroundImage,
  pt,
  pb,
  withBgMask,
}) => (
  <HeroContainerStyled
    backgroundImage={['', '', `${containerBackgroundImage}`]}
  >
    <Inner>
      <HeroBgStyled {...{ withBgMask }}>
        <HeroStyled pt={pt} pb={pb}>
          {children}
        </HeroStyled>
      </HeroBgStyled>
    </Inner>
  </HeroContainerStyled>
)

export default Hero
