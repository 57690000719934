import styled, { css } from 'styled-components'
import { color } from 'styled-system'

import { HEADER_TYPES } from '../../constants/headerTypes'
import { media, customMedia } from '../../common/MediaQueries'
import Container from '../Container'

const HeaderWrapperStyled = styled.header`
  ${color}
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ theme }) => theme.headerHeight.mobile};
  z-index: ${({ theme }) => theme.zIndex.index10};

  ${({ headerType, theme }) =>
    headerType === HEADER_TYPES.dark &&
    css`
      background-color: ${theme.colors.darkNavy};
      color: ${theme.colors.white};

      ${HeaderNavigationStyled} {
        @media ${customMedia.maxPhone} {
          background-color: ${theme.colors.darkNavy};
        }
      }
    `}

  ${({ headerType, theme }) =>
    headerType === HEADER_TYPES.light &&
    css`
      background-color: ${theme.colors.white};
      color: ${theme.colors.darkGrey2};

      ${HeaderNavigationStyled} {
        @media ${customMedia.maxPhone} {
          background-color: ${theme.colors.white};
        }
      }
    `}
  

  @media ${media.phone} {
    height: ${({ theme }) => theme.headerHeight.desktop};
  }
`

const HeaderStyled = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;

  @media ${media.phone} {
    align-items: flex-start;
    padding: 2.125rem 0;
  }
`

const HeaderLogoStyled = styled.div`
  z-index: 10;
`

const HeaderNavigationWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`

const HeaderNavigationStyled = styled.div`
  display: flex;
  transition: 500ms cubic-bezier(0.19, 1, 0.22, 1) 200ms;
  z-index: 2;

  @media ${customMedia.maxPhone} {
    position: fixed;
    top: 0;
    left: -100vw;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transform: translateX(0);

    ${({ isOpened }) =>
      isOpened &&
      css`
        transform: translateX(100vw);
        visibility: visible;
        opacity: 1;
      `}
  }

  @media ${media.phone} {
    transition: none;
    justify-content: center;
  }
`

export {
  HeaderWrapperStyled,
  HeaderStyled,
  HeaderLogoStyled,
  HeaderNavigationWrapperStyled,
  HeaderNavigationStyled,
}
