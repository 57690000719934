import React from 'react'

import ButtonLinkStyled, {
  ButtonLinkType,
  ButtonLinkSize,
  ButtonLinkWidth,
} from './styles'

interface IProps {
  type?: ButtonLinkType
  size?: ButtonLinkSize
  fluid?: ButtonLinkWidth
  left?: boolean
  className?: string
  href: string
  dark?: boolean
  target?: string
  rel?: string
}

const ButtonLink: React.FC<IProps> = ({ children, type, ...rest }) => (
  <ButtonLinkStyled {...rest} buttonType={type}>
    {children}
  </ButtonLinkStyled>
)

ButtonLink.defaultProps = {
  type: ButtonLinkType.primary,
  size: ButtonLinkSize.medium,
}

export default ButtonLink
