import React from 'react'
import { OverflowProps } from 'styled-system'

import Inner from '../Inner'
import { SectionHeadStyled, SectionBlockStyled } from './styles'

import { IInfobox } from '../../interfaces/IInfobox'
import { ITestimonial } from '../../interfaces/ITestimonial'

export interface IInfoboxItem {
  items: IInfobox[]
}

export interface ITestimonialItem {
  items: ITestimonial[]
}

interface IProps extends OverflowProps {
  heading?: React.ReactNode
  desc?: React.ReactNode
  action?: React.ReactNode
  hasCustomInner?: boolean
  pbHead?: string[]
  pbDesc?: string[]
  pbBody?: string[]
}

const Section: React.FC<IProps> = ({
  pbHead,
  pbDesc,
  pbBody,
  heading,
  desc,
  action,
  children,
  hasCustomInner,
}) => (
  <>
    {hasCustomInner ? (
      <>
        <Inner>
          <SectionHeadStyled pb={pbHead}>{heading}</SectionHeadStyled>
          {desc && (
            <SectionBlockStyled desc pb={pbDesc}>
              {desc}
            </SectionBlockStyled>
          )}
        </Inner>
        <SectionBlockStyled pb={pbBody}>{children}</SectionBlockStyled>
        {action && (
          <Inner>
            <SectionBlockStyled action>{action}</SectionBlockStyled>
          </Inner>
        )}
      </>
    ) : (
      <Inner>
        <SectionHeadStyled pb={pbHead}>{heading}</SectionHeadStyled>
        {desc && (
          <SectionBlockStyled desc pb={pbDesc}>
            {desc}
          </SectionBlockStyled>
        )}
        {children && (
          <SectionBlockStyled body pb={pbBody}>
            {children}
          </SectionBlockStyled>
        )}
        {action && <SectionBlockStyled action>{action}</SectionBlockStyled>}
      </Inner>
    )}
  </>
)

export default Section
